import { getStoreInstance, setupOrganizationsInStore } from '../store';
import { ACCOUNTANT_ROLE } from '../store/User';
import moment from 'moment';
import { toJS } from 'mobx';
import { useRouter } from 'next/router';

export default function Index({ organization, user }) {
  const router = useRouter();

  if (organization) {
    if (user.role === ACCOUNTANT_ROLE) {
      router.push(`/${organization.name}/rentalactivity/${moment().year()}`);
    } else {
      router.push(`/${organization.name}/dashboard`);
    }
  } else {
    router.push('/signin');
  }

  return null;
}

export async function getServerSideProps(context) {
  // redirect if not signed in
  const store = getStoreInstance();

  const { status } = await store.user.refreshTokens(context);
  if (status !== 200) {
    return { props: {} };
  }

  await setupOrganizationsInStore();
  if (!store.user.signedIn) {
    return { props: {} };
  }

  return {
    props: {
      organization: toJS(store.organization.selected),
      user: toJS(store.user)
    }
  };
}
